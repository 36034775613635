<script setup>
import { useVModel } from '@vueuse/core';

import useForm from '@/composables/useForm';
import { ref, computed, onMounted } from '#imports';
import { Field as VeeField } from 'vee-validate';
import CIcon from '@/components/common/CIcon.vue';

const formComposable = useForm();

const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    required: true,
  },
  modelValue: {
    type: Boolean,
    required: true,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  showErrors: {
    type: Boolean,
    default: true,
  },
  isCenter: {
    type: Boolean,
    default: false,
  },
  hideErrorText: {
    type: Boolean,
    default: false,
  },
  theme: {
    type: String,
    default: undefined,
    validator(theme) {
      return [''].includes(theme);
    },
  },
});

const emit = defineEmits(['update:modelValue']);
const localValue = useVModel(props, 'modelValue', emit);

/**
 * @description определяет, должна ли отображаться ошибка на основе значений showErrors и hideErrorText в props.
 * @param {Boolean} showErrors - должна ли отображаться ошибка вообще и применяться атрибуты aria.
 * @param {Boolean} hideErrorText - ошибка отображается, но при этом скрыт ее текст.
 */
const isShowErrors = computed(() => {
  return props.showErrors && !props.hideErrorText;
});

//<editor-fold desc="Availability">
const randomErrorId = ref();
const ariaDescribedby = ref();

onMounted(() => {
  randomErrorId.value = formComposable.getRandomCode('error');
  ariaDescribedby.value = randomErrorId.value;
});
//</editor-fold>
</script>

<template>
  <div
    class="ui-checkbox"
    :class="{
      [`ui-checkbox--theme--${props.theme}`]: props.theme,
      'ui-checkbox--center': props.isCenter,
    }"
  >
    <VeeField
      v-slot="{ errorMessage }"
      :name="name"
      :model-value="localValue"
      :unchecked-value="false"
      type="checkbox"
    >
      <div
        class="ui-checkbox__container"
        :class="{
          'ui-checkbox__container--inner-indent': isShowErrors,
          'ui-checkbox__container--disabled': isDisabled,
          'ui-checkbox__container--error': !!errorMessage,
          'ui-checkbox__container--active': localValue,
        }"
      >
        <label class="ui-checkbox__body">
          <input
            v-model="localValue"
            :name="name"
            type="checkbox"
            class="ui-checkbox__hidden"
            :disabled="isDisabled"
            :aria-describedby="ariaDescribedby"
            :aria-invalid="Boolean(props.showErrors && errorMessage)"
          />

          <span class="ui-checkbox__box">
            <CIcon
              name="check"
              class="ui-checkbox__icon ui-checkbox__icon--checked"
              aria-hidden="true"
            />
          </span>

          <span class="ui-checkbox__text">
            <slot />
          </span>
        </label>

        <span
          v-show="isShowErrors && errorMessage"
          class="ui-checkbox__footer"
          :title="errorMessage"
        >
          <span
            :id="randomErrorId"
            aria-live="assertive"
            class="ui-checkbox__font ui-checkbox__font--error"
          >
            {{ errorMessage }}
          </span>
        </span>
      </div>
    </VeeField>
  </div>
</template>

<style lang="scss" scoped>
.ui-checkbox {
  $parent: &;
  $font-family: $font-family-default;
  $color-basis: $color-black;
  $color-icon-checked: $color-blue-1;
  $color-bg-box: $color-grey-1;
  $color-bg-disabled-box: $color-grey-1;
  $color-border-box-disabled: $color-blue-2;
  $color-border-box: transparent;
  $color-bg-active-box: $color-blue-2;
  $color-hover: $color-blue-2;
  $color-hover-icon: $color-blue-3;
  $color-error: $color-red-1;
  $inner-indent: 24;

  position: relative;
  font-family: $font-family;
  color: $color-basis;
  cursor: pointer;

  &__font {
    &--error {
      @include text-overflow;

      font-size: em(13);
      font-weight: 400;
      line-height: 1.2;
      color: $color-error;
    }
  }

  &__icon {
    &--checked {
      flex-shrink: 0;
      width: em(24);
      height: em(24);
      color: $color-icon-checked;
      pointer-events: none;
      opacity: 0;
      transition: all 0.3s;
    }
  }

  &__container {
    border: 0 solid transparent;
    transition: border 0.3s;

    @include hover {
      #{$parent} {
        &__icon--checked {
          color: $color-hover-icon;
        }

        &__box {
          background: $color-hover;
        }
      }
    }

    &--error {
      #{$parent} {
        &__font--error {
          color: $color-error;
        }

        &__box {
          border-color: $color-error;
        }
      }
    }

    &--active {
      #{$parent} {
        &__icon--checked {
          opacity: 1;
        }

        &__box {
          background: $color-bg-active-box;
        }
      }
    }

    &--disabled {
      @include hover {
        #{$parent} {
          &__box {
            border-color: $color-border-box-disabled;
          }
        }
      }

      #{$parent} {
        &__box {
          pointer-events: none;
          touch-action: none;
          background: $color-bg-disabled-box;
        }

        &__body {
          cursor: default;
        }
      }
    }

    &--inner-indent {
      padding-bottom: em($inner-indent);
    }
  }

  &__body {
    display: flex;
    cursor: pointer;
  }

  &__footer {
    position: absolute;
    bottom: em(2);
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: em(52);
    text-align: left;
  }

  &__hidden {
    @include visually-hidden;
  }

  &__box {
    position: relative;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: em(36);
    height: em(36);
    margin-right: em(16);
    background: $color-bg-box;
    border: 1px solid $color-border-box;
    border-radius: em(8);
    transition: all 0.3s;
  }

  &--center {
    #{$parent} {
      &__body {
        align-items: center;
      }
    }
  }

  &--theme {
  }
}
</style>
